<template>
  <div v-if="show">
    <vxe-modal v-model="show" :title="name" width="500" min-width="500" height="220" min-height="220" showFooter
      esc-closable mask-closable resize>
      <template v-slot>
        <el-form ref="form" size="small" :model="data" label-width="auto" label-position="right" :rules="rules">
          <el-form-item prop="name" label="货位名称">
            <el-input v-model="data.name" placeholder="输入货位名称" type="text"></el-input>
          </el-form-item>
          <el-form-item prop="spaceNameId" label="仓库">
            <el-select filterable default-first-option v-model="data.spaceNameId" placeholder="选择仓库"
              style="width: 100%">
              <el-option :label="item.spaceName" :value="item.id" v-for="(item, key) in spaceName" :key="key">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </template>
      <template v-slot:footer>
        <div style="text-align: center">
          <vxe-button status="primary" style="width: 100%" @click="save" v-loading="loading">确定</vxe-button>
        </div>
      </template>
    </vxe-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      show: false,
      name: '',
      data: {},
      rules: {
        name: [{ required: true, trigger: 'blur', message: '输入货位名称' }],
        spaceNameId: [{ required: true, trigger: 'change', message: '选择仓库' }]
      },
      loading: false,
    }
  },
  computed: {
    ...mapGetters(['spaceName']),
  },
  methods: {
    /** 打开界面方法 */
    display(data) {
      this.show = true
      this.data = data.data
      this.name = data.name
      this.$store.dispatch('getSpaceName')
      this.$nextTick(() => {
        this.$refs.form.clearValidate()
      })
          
    },
    /** 保存方法 */
    save() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          const params = this.data
          this.$axios
            .post('/order/Location/create', params)
            .then(res => {
              if (res) {
                this.$message.success('创建成功')
                this.show = false
                this.$emit('init')
              }
              this.loading = false
            })
            .catch(err => {
              console.log('创建失败', err)
              this.loading = false
            })
        } else {
          return false
        }
      })
    }
  }
}
</script>